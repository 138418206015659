/* admin main styles */

.admin-content {
	margin: 24px 0 70px 320px;
}

.filters {

	.select-box {

		.react-select__multi-value {
			display: none;
		}
	}
}

/* File upload */

.file-upload {
	position: relative;
	color: $color-primary;
	text-align: center;
	transition: $trsn-dur;

	.file-upload-input {
		outline: none;
		display: none;
	}

	.file-upload-label {
		cursor: pointer;
		position: absolute;
		width: 100%;
		height: 100%;
		outline: none;
		top: 0;
		left: 0;
		display: flex;
		flex-direction: column;
		align-items: center;
		justify-content: center;
		border: 1px dashed $color-primary;
		background-color: rgba($color-blue-light, 0.4);
		border-radius: 18px;
	}

	&:hover {
		opacity: 0.7;
	}

	&.h-md {
		height: 90px;
	}

	&.disabled {
		pointer-events: none;
		opacity: 0.5;
	}

	&.invalid {

		.file-upload-label {
			border-color: $color-warning;
		}
	}

	&.sm {
		height: 45px;
		padding: 0 20px;
		width: 220px;

		.file-upload-label {
			flex-direction: row;
			border-radius: 5px;

			i {
				margin-right: 15px;
				font-size: 22px;
			}
		}
	}
}

.upload-process {
	&.sm {
			
		.doc-image {
			width: 28px;
			min-width: 28px;
			margin-right: 15px;

			img {
				width: 100%;
				height: auto;
			}
		}

		.doc-info {
			width: 380px;
		}
	}
}
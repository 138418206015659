/* Check elements */

// Checkbox styles

.checkbox {
  cursor: pointer;
  display: inline-flex;
  position: relative;

  .checkbox-label {
    transition: $trsn-dur;
    color: $color-secondary;
    @include text-overflow();
    padding-right: 5px;
  }

  &.disabled {
    pointer-events: none;
  }

  &:hover {
    opacity: 0.7;
  }

  &.checkbox-no-hover {
    &:hover {
      opacity: 1;
    }
  }

  &.br-corner {
    border-radius: $br-xl;
    padding: 5px 25px;
    border: 1px solid $color-border;
    width: 100%;

    .check-icon {
      position: absolute;
      top: 0;
      right: -1px;
      bottom: 0;
      width: 29px;
      min-width: 29px;
      height: 29px;
      border-radius: 50%;
      background-color: $color-blue-light;
      border: 1px solid $color-border;
      display: inline-block;

      &::before {
        content: ($icon-check);
        font-family: $font-icons;
        opacity: 0;
        transition: $trsn-dur-lg;
        color: #ffffff;
        position: absolute;
        font-size: $fs-sm;
        display: block;
        top: 7px;
        left: 7px;
      }
    }

    .checkbox-label {
      padding-left: 10px;
    }

    input {
      display: none;

      &:checked {
        & ~ .check-icon {
          opacity: 1;
          background-color: $color-primary;

          &::before {
            opacity: 1;
          }
        }
      }
    }
  }

  &.round {
    .check-icon {
      width: 18px;
      min-width: 18px;
      height: 18px;
      border-radius: $br-sm;
      border: 2px solid $color-primary;
      margin-right: 11px;
      transition: $trsn-dur-lg;
      display: inline-block;

      &::before {
        content: ($icon-check);
        font-family: $font-icons;
        opacity: 0;
        transition: $trsn-dur-lg;
        color: #ffffff;
        position: absolute;
        font-size: 15px;
        display: block;
        top: 1px;
        left: 2px;
        font-weight: $fw-600;
      }
    }

    input {
      display: none;

      &:checked {
        ~ .check-icon {
          opacity: 1;
          background-color: $color-primary;

          &::before {
            opacity: 1;
          }
        }
      }
    }
  }

  &.disabled {
    opacity: 0.5;
    pointer-events: none;
  }
}

.react-select__option {
  cursor: pointer;
  display: inline-flex!important;

  .check-icon {
    width: 18px;
    min-width: 18px;
    height: 18px;
    border-radius: $br-circle;
    border: 2px solid $color-primary;
    margin-right: 11px;
    transition: $trsn-dur-lg;
    display: inline-block;
    position: relative;

    &::before {
      content: ($icon-check);
      font-family: $font-icons;
      opacity: 0;
      transition: $trsn-dur-lg;
      color: #ffffff;
      position: absolute;
      font-size: 15px;
      display: block;
      @include XAlign();
      font-weight: $fw-600;
    }
  }

  input {
    display: none;

    &:checked {
      ~ .check-icon {
        opacity: 1;
        background-color: $color-primary;

        &::before {
          opacity: 1;
        }
      }
    }
  }
}

/* Radio buttons */

.radio-button {
  display: flex;
  align-items: center;
  cursor: pointer;

  &.circle {
    .radio-label {
      font-size: 16px;
      cursor: pointer;
      margin-left: 10px;
      transition: $trsn-dur;

      &:hover {
        opacity: 0.7;
      }
    }

    .radio-icon {
      width: 20px;
      min-width: 20px;
      height: 20px;
      border: 2px solid rgba($color-primary, 0.2);
      display: inline-block;
      border-radius: $br-circle;
      position: relative;

      &::after {
        content: "";
        display: inline-block;
        width: 10px;
        min-width: 10px;
        height: 10px;
        background-color: $color-primary;
        border-radius: $br-circle;
        position: absolute;
        top: 3px;
        right: 3px;
        opacity: 0;
        transition: $trsn-dur;
      }
    }

    input {
      display: none;

      &:checked {
        & ~ .radio-icon {
          display: block;
          border-color: $color-primary;

          &:after {
            opacity: 1;
          }
        }
      }
    }
  }

  &.br-corner {
    cursor: pointer;

    .radio-label {
      margin-left: 10px;
    }

    .radio-icon {
      width: 35px;
      height: 35px;
      min-width: 35px;
      border-radius: 18px 18px 18px 0;
      background-color: $color-blue-light;
      border: 1px solid $color-border;
      position: relative;

      &::after {
        content: "";
        width: 12px;
        min-width: 12px;
        height: 12px;
        border-radius: $br-circle;
        background-color: $color-primary;
        position: absolute;
        @include XYAlign();
        display: block;
        opacity: 0;
        transition: $trsn-dur;
      }
    }

    input {
      display: none;

      &:checked {
        & ~ .radio-icon {
          opacity: 1;
          background-color: #ffffff;
          border-width: 2px;
          border-color: $color-primary;

          &::after {
            opacity: 1;
          }
        }
      }
    }
  }
}

// Paths

$fonts-folder: "../../fonts";
$images-folder: "../img/";

// Fonts
$font-primary: "Open Sans";
$font-secondary: "Open Sans";
$font-icons: "Icomoon Lexy";

$font-family: (
  primary: $font-primary,
  secondary: $font-secondary,
);

// Color palette

$color-primary: #5386d4;
$color-primary-70: rgba($color-primary, 0.7);
$color-secondary: #000000;
$color-tertiary: #818ea7;
$color-highlight: #39a4cd;
$color-warning: #e43535;
$color-success: #0a9d00;
$color-grey: #707070;
$color-border: rgba($color-primary, 0.2);
$color-disable: rgba($color-primary, 0.3);

// Descriptive Base Colors

$color-text: $color-secondary;
$color-body: white;
$color-blue-light: #d4e1f4;
$color-blue-light-60: rgba($color-blue-light, 0.6);
$color-blue-middle: #395e97;
$color-green: #37b281;
$color-green-dark: #338f6a;
$color-blue-dark: #002e59;
$color-blue-lighter: #30699f;

$color-palette: (
  primary: $color-primary,
  primary-70: $color-primary-70,
  secondary: $color-secondary,
  tertiary: $color-tertiary,
  highlight: $color-highlight,
  warning: $color-warning,
  white: #ffffff,
  white-30: rgba(#ffffff, 0.3),
  grey: $color-grey,
  black-20: rgba(#000000, 0.2),
  black-30: rgba(#000000, 0.3),
  black-50: rgba(#000000, 0.5),
  black-60: rgba(#000000, 0.6),
  black-70: rgba(#000000, 0.7),
  blue-light: $color-blue-light,
  blue-light-60: $color-blue-light-60,
) !default;

// Font-sizes

$fs-xs: 12px;
$fs-sm: 14px;
$fs-md: 16px;
$fs-lg: 18px;
$fs-xl: 24px;
$fs-xxl: 30px;

// Base font size

$base-font-size: $fs-md;

$text-font-sizes: (
  xs: $fs-xs,
  sm: $fs-sm,
  md: $fs-md,
  lg: $fs-lg,
  xl: $fs-xl,
  xxl: $fs-xxl,
) !default;

/* Heading sizes are not final ones. Need to be specified with the design team. */

$heading-font-sizes: (
  h6: $fs-xs,
  h5: $fs-sm,
  h4: $fs-md,
  h3: $fs-lg,
  h2: $fs-xl,
  h1: $fs-xxl,
) !default;

// Font weights

$fw-100: 100;
$fw-200: 200;
$fw-300: 300;
$fw-400: 400;
$fw-500: 500;
$fw-600: 600;
$fw-700: 700;
$fw-800: 800;
$fw-900: 900;

$font-weights: (
  100: $fw-100,
  200: $fw-200,
  300: $fw-300,
  400: $fw-400,
  500: $fw-500,
  600: $fw-600,
  700: $fw-700,
  800: $fw-800,
  900: $fw-900,
);

// Border radius

$br-sm: 2px;
$br-default: 4px;
$br-md: 5px;
$br-lg: 6px;
$br-xl: 18px;
$br-xxl: 29px;
$br-circle: 50%;
$br-none: 0;
$br-corner-xs: 0;
$br-corner-sm: 0;
$br-corner-lg: 0;

// Transitions

$trsn-dur: 0.1s;
$trsn-dur-md: 0.2s;
$trsn-dur-lg: 0.3s;
$trsn-dur-xl: 0.5s;
$trsn-delay: 50ms;

// Container

$cont-width: 1170px;
$cont-sm-width: 830px;
$cont-padding: 30px;
$fluid-cont-padding: 60px;

// Resolutions

$screen-lg: 1280px;
$screen-md: 1024px;
$screen-sm: 768px;
$screen-xs: 480px;
$screen-xxs: 360px;

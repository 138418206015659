#not-found {
    height: 100vh;
    display: flex;
    justify-content: center;
    align-items: center;

    .not-found {
        padding: 30px;
        text-align: center;

        h1 {
            font-size: 180px;
            line-height: 1;
            margin-bottom: 60px;
            font-weight: normal;
            color: #7EA4DF;
        }

        h4 {
            text-transform: uppercase;
            letter-spacing: 3px;
        }

        p {
            font-size: 16px;
        }

        .btn {
            margin-top: 23px;
        }
    }
}
/* Aside styles */

aside {
	background-color: $color-blue-dark;
	position: absolute;
	top: 0;
	bottom: 0;
	left: 0;
	width: 100%;
	max-width: 320px;
	box-shadow: 1px 1px 3px rgba(#4167A1, 0.3);
	overflow-y: auto;
	z-index: 1;

	/* When the logo will be ready the code for the logo needs to be revised */

	.header {
		background-color: rgba(#fff, 0.1);
		margin-bottom: 48px;
		height: 64px;
	}

	.logo {
		max-width: 110px;
		margin-left: 67px;

		a {
			font-size: 21px;
			font-family: $font-secondary;
			color: rgba(#FFFFFF, 0.5);
			font-weight: $fw-600;
			line-height: 64px;
			display: block;

			img {
				max-width: 100%;
				vertical-align: middle;
			}
		}
	}

	.nav {
		margin-bottom: 30px;

		li {
			color: rgba(#FFFFFF, 0.7);
			font-family: $font-secondary;
			font-weight: $fw-400;
			text-transform: capitalize;
			height: 45px;
			line-height: 45px;
			position: relative;
			display: flex;

			a {
				display: inline-block;
				width: 100%;
				padding-left: 67px;
				color: rgba(#FFFFFF, 0.7);

				i {
					position: absolute;
					left: 32px;
					line-height: 45px;
					font-size: 17px;

					&.icon-star,
					&.icon-plus {
						font-size: 20px;
					}
				}

				&:before {
					content: '';
					display: block;
					position: absolute;
					top: 0;
					bottom: 0;
					left: 0;
					background-color: transparent;
					border-radius: 10px;
					width: 5px;
				}

				&.active {
					background-color: rgba(#000000, 0.15);
					color: #FFFFFF;

					&:before {
						background-color: #FFFFFF;
					}

					~ span {
						background-color: rgba(#000000, 0.15);
						color: #FFFFFF;
					}
				}

				&:hover {
					color: #FFFFFF;
					opacity: 1;
				}
			}

			span {
				padding-right: 15px;
				width: 100%;
				max-width: 65px;
				@include text-overflow();
			}

			&.title {
				margin-left: 67px;
				border-bottom: 1px solid rgba(#FFFFFF, 0.1);
				text-transform: uppercase;
				color: rgba(#FFFFFF, 0.4);
				margin-right: 40px;
				font-weight: $fw-600;
			}
		}

		li, a {
			max-width: 320px;
			@include text-overflow();
		}
	}
}

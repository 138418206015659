@each $key,$val in $resolutions {
  @media (min-width: $val) {
    // Alignment
    .text-#{$key}-left {
      text-align: left;
    }
    .text-#{$key}-right {
      text-align: right;
    }
    .text-#{$key}-center {
      text-align: center;
    }
    .text-#{$key}-justify {
      text-align: justify;
    }
  }
}

.text-center {
  text-align: center;
}

.text-left {
  text-align: left;
}

.text-right {
  text-align: right;
}

// Transformation

.text-lowercase {
  text-transform: lowercase;
}
.text-uppercase {
  text-transform: uppercase;
}
.text-capitalize {
  text-transform: capitalize;
}

// Ellipsis

.ellipsis {
  @include text-overflow();
}

// Word break

.word-break-all {
  word-break: break-all;
}


// Info text

.info-text {
  color: rgba(#000, .7);
  font-style: italic;
  font-size: 13px;
  line-height: 17px;
}
/* Breadcrumbs styles */

.breadcrumbs {

	li {
		display: inline-block;
	}

	// Website's breadcrumbs

	&.main {
		margin-top: 37px;

		li {

			a {
				margin-right: 10px;
				color: rgba(#FFFFFF, 0.7);

				&.active {
					color: #ffffff;
					pointer-events: none;
				}
			}

			&::after {
				content: ($icon-arrow-right);
				font-family: $font-icons;
				display: inline-block;
				font-size: 10px;
				margin-right: 10px;
			}

			&:last-child {

				&::after {
					display: none;
				}
			}
		}
	}

	// Dashboard's breadcrumbs

	&.dashboard {
		margin-left: 320px;
		background-color: #ffffff;
		padding: 8px 40px;
		border-bottom: 1px solid $color-border;

		li {

			a {
				font-family: $font-secondary;
				color: $color-primary;
				font-weight: $fw-600;
				padding-right: 15px;

				&.active {
					font-weight: $fw-400;
					color: rgba(#000000, 0.5);
					pointer-events: none;
				}
			}

			&::after {
				content: ($icon-arrow-right);
				font-family: $font-icons;
				display: inline-block;
				font-size: 8px;
				color: rgba(#000000, 0.2);
				margin-right: 15px;
			}

			&:last-child {

				&::after {
					display: none;
				}
			}
		}
	}
}
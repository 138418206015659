@import "variables";

[class^="icon-"], [class*=" icon-"] {
    /* use !important to prevent issues with browser extensions that change fonts */
    font-family: '#{$font-icons}' !important;
    speak: none;
    font-style: normal;
    font-weight: normal;
    font-variant: normal;
    text-transform: none;
    line-height: 1;

    /* Better Font Rendering =========== */
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}

.icon-hyperlink {
    &:before {
        content: $icon-hyperlink;
    }
}
.icon-redo {
    &:before {
        content: $icon-redo;
    }
}
.icon-undo {
    &:before {
        content: $icon-undo;
    }
}
.icon-underline {
    &:before {
        content: $icon-underline;
    }
}
.icon-strikethrough {
    &:before {
        content: $icon-strikethrough;
    }
}
.icon-italic {
    &:before {
        content: $icon-italic;
    }
}
.icon-bold {
    &:before {
        content: $icon-bold;
    }
}
.icon-corner {
    &:before {
        content: $icon-corner;
    }
}
.icon-add {
    &:before {
        content: $icon-add;
    }
}
.icon-print {
    &:before {
        content: $icon-print;
    }
}
.icon-download {
    &:before {
        content: $icon-download;
    }
}
.icon-note {
    &:before {
        content: $icon-note;
    }
}
.icon-edit {
    &:before {
        content: $icon-edit;
    }
}
.icon-lock {
    &:before {
        content: $icon-lock;
    }
}
.icon-arrow-up {
    &:before {
        content: $icon-arrow-up;
    }
}
.icon-arrow-down {
    &:before {
        content: $icon-arrow-down;
    }
}
.icon-camera {
    &:before {
        content: $icon-camera;
    }
}
.icon-avatar {
    &:before {
        content: $icon-avatar;
    }
}
.icon-time {
    &:before {
        content: $icon-time;
    }
}
.icon-delete {
    &:before {
        content: $icon-delete;
    }
}
.icon-arrow-left {
    &:before {
        content: $icon-arrow-left;
    }
}
.icon-file {
    &:before {
        content: $icon-file;
    }
}
.icon-link {
    &:before {
        content: $icon-link;
    }
}
.icon-close {
    &:before {
        content: $icon-close;
    }
}
.icon-upload {
    &:before {
        content: $icon-upload;
    }
}
.icon-clock {
    &:before {
        content: $icon-clock;
    }
}
.icon-check {
    &:before {
        content: $icon-check;
    }
}
.icon-doc {
    &:before {
        content: $icon-doc;
    }
}
.icon-search {
    &:before {
        content: $icon-search;
    }
}
.icon-arrow-right {
    &:before {
        content: $icon-arrow-right;
    }
}
.icon-arrow-back {
  &:before {
    content: $icon-arrow-back; 
  }
}
.icon-plus {
  &:before {
    content: $icon-plus; 
  }
}
.icon-star {
  &:before {
    content: $icon-star; 
  }
}
.icon-eye {
  &:before {
    content: $icon-eye; 
  }
}
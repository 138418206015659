/* admin manage component styles styles */


.manage {

	.manage-file {
		padding: 11px 25px 11px 35px;
		display: flex;
		align-items: center;
		margin-bottom: 7px;

		.public-document {
			margin-left: auto;
			margin-right: 25px;
			margin-top: 1px;

			i {
				color: $color-primary;
				display: block;
				font-size: 24px;
			}
		}

		.icon-arrow-right {
			margin-left: auto;
		}

		.content {
			width: 100%;
			max-width: 660px;
			margin-right: 30px;
		}

		.title {
			font-family: $font-secondary;
			font-weight: $fw-600;
			font-size: $fs-md;
		}

		.card-item-left {
			flex-grow: 1;
		}

		.card-item-right {
			width: 40%;
			padding-right: 15px;
		}

		.sub-content {
			color: rgba(#000000, 0.5);
			margin-top: 5px;

			a {
				color: $color-primary;
			}
		}

		.actions {
			display: flex;

			li {
				.btn-icon {
					font-size: $fs-xl;
				}
			}

			.delete {
				color: $color-warning;
			}

			.approve {
				color: $color-success;
			}
		}

		&.disabled {
			opacity: 0.5;
			pointer-events: none;
		}
	}

	.user-image {
		max-width: 170px;
		margin-right: 50px;
		display: flex;
		flex-direction: column;
		align-items: center;
		position: relative;

		.error-msg {
			font-size: 14px;
			font-style: normal;
			bottom: -50px;
		    width: calc(100% + 40px);
		    left: -20px;
		}

		.image {
			width: 128px;
			min-width: 128px;
			height: 128px;
			border-radius: $br-circle;
			border: 1px dashed rgba($color-primary, 0.6);
			background-color: rgba($color-blue-light, 0.4);
			background-repeat: no-repeat;
			background-position: center;
			background-size: cover;
			position: relative;
			overflow: hidden;

			.hover-content {
				position: absolute;
				bottom: 0;
				left: 0;
				right: 0;
				background-color: rgba($color-primary, 0.85);
				color: #ffffff;
				padding: 10px 0;
				transition: $trsn-dur-lg;
				visibility: hidden;
				opacity: 0;

				label {
					cursor: pointer;
				}

				input {
					display: none;
				}
			}
		}

		&.uploaded {

			.image {
				cursor: pointer;
				border: none;

				&:hover {

					.hover-content {
						visibility: visible;
						opacity: 1;
					}
				}
			}
		}

		.image-upload {
			cursor: pointer;
			transition: $trsn-dur;
			color: $color-primary;
			display: inline-block;
			margin-top: 18px;

			input {
				display: none;
			}

			i {
				margin-right: 9px;
			}

			&:hover {
				opacity: 0.7;
			}
		}

		&.uploaded {

		}
	}
}

/* List view */

.list-view {

	li {
		margin-bottom: 38px;

		.label {
			color: rgba(#000000, 0.5);
			margin-bottom: 10px;
			display: inline-block;
		}

		.value {
			font-size: $fs-md;
		}
	}
}

/* Tabs */

.tabs {

	.tab {
		background-color: #ffffff;
		border: 1px solid $color-border;
		padding: 12px 0;
		width: 50%;
		text-align: center;
		cursor: pointer;
		transition: $trsn-dur;

		&.left {
			border-radius: 25px 0 0 6px;
		}

		&.right {
			border-radius: 0 25px 6px 0;
		}

		&.active {
			background-color: rgba(#D4E1F4, 0.5);
			border-color: $color-primary;

			p, i {
				color: $color-primary;
			}
		}

		&:hover {
			opacity: 0.7;
		}
	}
}

/*  Custom scroll */

.rcs-custom-scroll .rcs-inner-handle {
	background-color: rgba(#000000, 0.3)!important;
}

.scrollbar-container {
	padding-right: 15px;
	width: calc(100% + 15px);
}

.ps .ps__rail-x:hover, .ps .ps__rail-y:hover, .ps .ps__rail-x:focus, .ps .ps__rail-y:focus, .ps .ps__rail-x.ps--clicking, .ps .ps__rail-y.ps--clicking {
	background-color: transparent!important;
}

.ps__rail-y:hover > .ps__thumb-y, .ps__rail-y:focus > .ps__thumb-y, .ps__rail-y.ps--clicking .ps__thumb-y {
	width: 6px!important;
	cursor: pointer;
}
/* Pagination */

.pagination {
	display: flex;
	align-items: center;
	justify-content: center;
	margin-top: 35px;

	li {

		a {
			display: inline-block;
			text-align: center;
			margin: 0 5px;
			font-size: $fs-md;
			width: 30px;
			min-width: 30px;
			height: 30px;
			line-height: 30px;
			border-radius: $br-circle;

			&.active {
				background-color: $color-primary;
				color: #ffffff;
			}

			&.disabled {
				opacity: 0.5;
				pointer-events: none;
			}
		}
	}
}
/* Pricing resp styles */


@media (max-width: 1200px) {

  .user-pricing {
    padding-bottom: 50px;

    .plan-head {
      padding: 50px 25px 25px;
    }

    .plan-body {
      padding: 30px 20px 40px;
    }

    .plan-features {
      margin-bottom: 30px;

      li {
        font-size: 14px;
        line-height: 17px;
        margin-bottom: 12px;
        padding-left: 35px;

        &:before {
          font-size: 22px;
        }
      }
    }
  }
}

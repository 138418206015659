/* Switch styles */
.switch-wrap {
  display: flex;
  align-items: center;

  .switch-text {
    margin-left: 12px;
    font-size: 16px;
  }
}

.switch {
  display: inline-block;
  width: 34px;
  height: 14px;
  background-color: rgba(#221F1F, .26);
  border-radius: 16px;
  position: relative;
  cursor: pointer;

  .toggle {
    width: 20px;
    height: 20px;
    background-color: #F1F1F1;
    display: inline-block;
    border-radius: $br-circle;
    position: absolute;
    left: -3px;
    @include YAlign();
    transition: 0.3s ease;
    box-shadow: 0px 2px 2px 0px rgba(0,0,0,0.24);
  }

  input {
    display: none;

    &:checked {

      & ~ .toggle {
        left: 17px;
        background: $color-primary;
      }
    }
  }
}
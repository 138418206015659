/* Progress bar styles */

.progress-bar {
	width: 100%;
	height: 6px;
	background-color: $color-blue-light;
	border-radius: 3px;
	position: relative;

	.filled {
		position: absolute;
		height: 100%;
		border-radius: 3px;
		left: 0;
		background-color: $color-primary;
	}
}
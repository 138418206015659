/* Button styles */

.btn {
  font-family: $font-secondary;
  font-weight: $fw-700;
  font-size: $fs-sm;
  cursor: pointer;
  text-align: center;
  white-space: nowrap;
  transition: $trsn-dur-lg;
  padding: 0 15px;
  display: inline-block;

  // Filled buttons

  &.filled {
    border: 0;
    color: #ffffff;

    @each $key, $val in $color-palette {
      &.#{$key} {
        background-color: #{$val};
      }
    }
  }

  // Outline buttons

  &.outline {
    line-height: 36px;
    background-color: rgba(#ffffff, 0.2);
    border: 1px solid transparent;

    @each $key, $val in $color-palette {
      &.text-#{$key} {
        color: #{$val};
      }
    }
    @each $key, $val in $color-palette {
      &.border-#{$key} {
        border-color: #{$val};
      }
    }
  }

  // Link buttons

  &.link {
    border: 0;
    background-color: transparent;
    box-shadow: none;

    @each $key, $val in $color-palette {
      &.#{$key} {
        color: #{$val};
      }
    }

    &:active {
      background-color: transparent !important;
    }
  }

  &:hover {
    opacity: 0.7;
  }

  &.br-corner {
    border-radius: $br-corner-sm;
  }

  &.shadow {
    box-shadow: 0 3px 6px rgba(#000000, 0.16);
  }

  &[disabled],
  &.disabled {
    border: 0;
    pointer-events: none;
    color: rgba(#ffffff, 0.6);
    background-color: $color-disable !important;
  }

  // Buttons sizes

  &.w-sm {
    min-width: 130px;
  }

  &.w-md {
    min-width: 152px;
  }

  &.w-lg {
    min-width: 200px;
  }

  &.w-xl {
    min-width: 232px;
  }

  &.h-sm {
    height: 38px;

    &.outline {
      line-height: 36px;
    }

    &.filled {
      line-height: 38px;
    }

    &.link {
      line-height: 38px;
    }
  }

  &.h-lg {
    height: 47px;

    &.outline {
      line-height: 45px;
    }

    &.filled {
      line-height: 47px;
    }

    &.link {
      line-height: 47px;
    }
  }
}

/* Buttons including icons */

.btn-icon {
  background: none;
  border: none;
  cursor: pointer;
  transition: $trsn-dur;
  display: flex;

  &:hover {
    opacity: 0.7;
  }
}

/* Footer styles */

footer {
  background-color: $color-blue-lighter;
  padding: 16px 0;
  margin-top: 128px;

  &.dashboard {
    margin-left: 320px;
  }
}

/* Phone number validation plugin styles */

.react-phone-number-input {

	.rrui__input {

		.rrui__select__native {
			display: none;
		}

		.rrui__select__button {
			background: none;
			border: none;
			margin-right: 15px;
			cursor: pointer;
			transition: $trsn-dur;

			&:hover {
				opacity: 0.7;
			}
		}

		.react-phone-number-input__icon--international {
			line-height: 36px;
		}

		.rrui__options-list {
			position: absolute;
			top: 50px;
			left: 0;
			right: 0;
			z-index: 1;
			border-radius: 6px;
			max-height: 200px;
			overflow-y: auto;
			background-color: #ffffff;
			border: 2px solid $color-primary;
		}

		.rrui__list__item {
			background-color: transparent;
			border: none;
			padding: 10px 27px;
			font-size: $fs-sm;
			font-family: $font-primary;
		}

		.rrui__expandable__close {
			display: none;
		}
	}
}

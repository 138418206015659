/* Search results page */

.search-results {

	.items {

		.filter {
			width: 100%;
			max-width: 399px;

			.card {
				padding: 36px 9px 67px 9px;
				margin-right: 15px;

				.scrollbar-container {
					padding-right: 0;
					width: 100%;
				}

				&.search-view {
					padding: 50px 0;

					h5 {
						margin-left: 60px;
					}

					.list {
						margin-bottom: 25px;
						padding: 10px 25px 10px;
						border-bottom: 1px solid $color-border;

						&:last-child {
							border-bottom: none;
							padding-bottom: 0;
							margin-bottom: 0;
						}
					}
				}
			}

			.filter-list {
				padding-left: 20px;

				li {
					margin-bottom: 8px;

					.checkbox {
						max-width: 320px;
					}
				}
			}
		}

		.files {
			width: 100%;

			.file {
				display: flex;
				align-items: center;
				justify-content: space-between;
				padding: 14px 24px 14px 25px;
				margin-bottom: 8px;
				cursor: pointer;
				transition: $trsn-dur;
				position: relative;

				.paid-document {
					position: relative;
					padding: 6px;
					width: 24px;
					height: 24px;
					margin-left: auto;
					margin-right: 20px;

					&:hover {

						.tooltip-text {
							opacity: 1;
							visibility: visible;
						}
					}

					.dot {
						width: 12px;
						height: 12px;
						border-radius: $br-circle;
						background: $color-green;
						display: block;
					}

					.tooltip-text {
						font-size: 12px;
						position: absolute;
						left: 0;
						top: 50%;
						transform: translate(-100%, -50%);
						padding: 6px 8px 8px;
						background: rgba(#000, .8);
						color: rgba(#fff, .8);
						min-width: 130px;
						border-radius: $br-default;
						text-align: center;
						opacity: 0;
						visibility: hidden;
						transition: $trsn-dur-lg;

						&:after {
							content: "";
							position: absolute;
							@include YAlign;
							right: -5px;
							width: 0;
							height: 0;
							border-top: 6px solid transparent;
							border-left: 6px solid rgba(#000, .8);
							border-bottom: 6px solid transparent;
						}
					}
				}

				.file-info {
					display: flex;
					align-items: center;
					margin-right: 30px;

					.details {
						margin-right: 25px;
					}

					.icon-doc {
						font-size: 20px;
						margin-right: 25px;
					}

					.title {
						font-family: $font-secondary;
						font-weight: $fw-600;
					}

					.desc {
						color: rgba(#000000, 0.5);

						a {
							color:#5386D4;
						}
					}

					.title, .desc {
						max-width: 560px;
						@include text-overflow();

						a {
							color: $color-primary;
						}
					}
				}

				i {
					color: $color-primary;
				}

				.icon-arrow-right {
					font-size: $fs-xs;
				}

				&:hover {

					.file-info,
					.icon-arrow-right {
						opacity: 0.7;
					}
				}
			}
		}
	}
}
@mixin font-face($font-name, $font-face-name, $font-style, $font-weight) {
  $file-path: $fonts-folder +'/'+ $font-name;
  @font-face {
    font-family: $font-face-name;
    font-style: $font-style;
    font-weight: $font-weight;
    src: url($file-path + ".eot");
    src: url($file-path + ".eot?#iefix") format('embedded-opentype'),
    url($file-path + ".woff") format('woff'),
    url($file-path + ".ttf") format('truetype'),
    url($file-path + ".svg") format('svg');
  }
}

// Background image

@mixin bgImage {
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
}

@mixin position($position: absolute, $top: null, $right: null, $bottom: null, $left: null) {
  position: $position;
  top: $top;
  right: $right;
  bottom: $bottom;
  left: $left;
}

@mixin clear-after {
  &::after {
    content: " ";
    clear: both;
    display: block;
  }
}

@mixin text-overflow() {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

// Alignment

@mixin YAlign {
  top: 50%;
  transform: translateY(-50%);
}

@mixin XAlign {
  left: 50%;
  transform: translateX(-50%);
}

@mixin XYAlign {
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}


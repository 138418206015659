$resolutions: (
		xxs: $screen-xxs,
		xs: $screen-xs,
		sm: $screen-sm,
		md: $screen-md,
		lg: $screen-lg
) !default;

$spaces: (
		0: 0!important,
		1: 5px,
		2: 10px,
		3: 15px,
		4: 20px,
		5: 25px,
		6: 30px,
		7: 35px,
		8: 40px,
		9: 45px,
		10: 50px
) !default;

$grid-cols-count: 12;
$grid-spacing: 10px;
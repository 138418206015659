/* Edit doc page */

.edit-doc {
  .edit-doc-info {
    font-size: 16px;
    margin-bottom: 25px;
  }

  .sticky {
    position: fixed;
    top: 0;
    z-index: 3;
    border: 1px solid rgba(#436baa, 0.2);
    border-top: 0;
    background-color: #ffffff;
    padding: 10px;
    width: 100%;
    max-width: 623px;
    border-bottom-left-radius: $br-corner-sm;
    border-bottom-right-radius: $br-corner-sm;
    transition: all $trsn-dur;
    box-shadow: 5px 5px 13px rgba($color-primary, 0.16);
  }

  .edit-doc-content {
    flex-grow: 1;
  }

  .lawyer-details {
    .lawyer-img {
      @include bgImage();
      width: 85px;
      height: 85px;
      min-width: 85px;
      background-color: $color-primary;
      border-radius: $br-circle;
      display: inline-block;
      line-height: 85px;
      text-align: center;
      color: #ffffff;
      font-size: $fs-xl;
    }

    li {
      margin-bottom: 15px;
    }
  }

  .doc-content {
    overflow: hidden;

    .card-header {
      background-color: $color-body;
      display: flex;
      justify-content: space-between;

      .loader {
        padding: 5px 0 0 20px;
      }
    }

    .card-body {
      border-top: 1px solid rgba(#436baa, 0.2);
      padding: 25px 50px 50px 50px;

      .new-note {
        h6 {
          background-color: #395b90;
        }
      }
    }

    .ql-container,
    .ql-toolbar {
      &.ql-snow {
        border: none;
      }
    }

    ul {
      padding-left: 0;

      li {
        padding-left: 0 !important;

        &:before {
          display: none;
        }

        br {
          display: none;
        }
      }
    }

    .edit-doc-section {
      //font-family: $font-primary;
      //color: rgba(#000000, 0.7);
      transition: $trsn-dur;
      border: 1px solid transparent;
      border-radius: 16px;
      padding: 10px 12px !important;
      position: relative;
      margin-bottom: 5px;
      min-height: 40px;

      &:hover,
      &.hovered {
        background-color: rgba($color-green, 0.12) !important;
        border-color: $color-green-dark;
      }

      &.empty {
        padding: 0 12px !important;
        margin: 0 !important;
        min-height: 5px;
        border: 0 !important;
        background-color: white !important;
      }

      .add-note-icon {
        position: absolute;
        right: -15px;
        z-index: 999;
        background-color: $color-green;
        color: #ffffff;
        border: 1px solid $color-green-dark;
        outline: 0;
        cursor: pointer;
        font-size: 13px;
        font-family: $font-secondary;
        font-weight: $fw-600;
        transition: all $trsn-dur-md;
        height: 36px;
        width: 36px;
        min-width: 36px;
        border-radius: $br-circle;

        &:after {
          content: ($icon-add);
          font-family: $font-icons;
          position: absolute;
          @include XYAlign();
          right: 2px;
        }
      }
    }

    .draft-title {
      border: none;
    }

    .draftingNote {
      // border: 1px solid rgba($color-green, 0.4);
      // border-radius: 0 0 0 16px;
      padding-top: 11px;
      padding-bottom: 11px;
      position: relative;
      margin: 22px 12px 15px 12px;
      transition: $trsn-dur;
      // user-select: none; // commenting these out b/c we need -webkit-user-select:text for safari in some places
      background-color: #f8f6ef;

      .note-title {
        font-family: $font-secondary;
        font-weight: $fw-700;
        padding: 0 55px;
        font-size: 13px;
        // user-select: none;
        border: none;
        background: transparent;
        width: 100%;
      }

      &.expanded {
        .btn-expand {
          &:after {
            content: ($icon-arrow-up);
          }
        }

        &::before {
          border-bottom-left-radius: 0;
        }

        .note-desc {
          display: block;
        }

        .btn-delete {
          display: inline-block;
        }

        .note-title {
          margin-bottom: 8px;
        }

        &::after {
          opacity: 1;
        }
      }

      &::before {
        content: ($icon-note);
        font-family: $font-icons;
        @include position($left: 10px, $top: 12px);
        height: 18px;
        line-height: 1;
        color: #d9b444;
        padding: 0 10px;
        font-size: $fs-md;
        border-bottom-left-radius: 16px;
        transition: $trsn-dur;
      }

      &::after {
        content: "";
        @include position($left: 0px, $top: -9px);
        width: 10px;
        height: 10px;
        background: url("../../../img/drafting-note-icon.svg") no-repeat center
          center/10px 10px;
        opacity: 0;
        transition: $trsn-dur;
      }
    }

    .note-desc {
      padding: 10px 20px 20px;
      font-family: $font-primary;
      color: rgba(#000000, 0.6);
      font-size: 13px;
      display: none;
      margin-bottom: 15px;
      cursor: default;

      &.show {
        display: block;
        // user-select: none;
      }
    }

    .btn-manage {
      color: $color-green;
      cursor: pointer;
      transition: $trsn-dur-lg;
      display: flex;
      align-items: center;
      background: transparent;
      border: none;

      &.btn-expand {
        @include position($right: 20px, $top: 12px);
        color: #d9b444;
        width: 20px;
        height: 18px;

        &:after {
          content: ($icon-arrow-down);
          font-family: $font-icons;
          display: block;
          position: absolute;
          @include YAlign();
          transition: all $trsn-dur;
          font-size: 12px;
        }
      }

      &.btn-delete {
        @include position($bottom: 10px, $right: 14px);
        font-family: $font-primary;
        font-size: $base-font-size;
        z-index: 1;
        color: $color-warning;
        display: none;

        &::before {
          font-family: $font-icons;
          content: ($icon-close);
          margin-right: 10px;
        }
      }

      &:hover {
        opacity: 0.7;
      }
    }

    // View mode styles

    &.view-mode {
      .editor-toolbar-row {
        display: none;
      }

      .edit-doc-section {
        &:hover {
          background-color: initial !important;
          border-color: transparent !important;
        }

        .add-note-icon {
          display: none;
        }
      }

      .btn-add {
        display: none !important;
      }
    }
  }

  .list-edit {
    .label {
      color: rgba(#000000, 0.5);
      margin-right: 15px;
    }

    .value {
      font-size: $fs-md;
    }
  }

  .doc-form-content {
    flex-shrink: 0;

    .checkbox {
      max-width: 90%;
    }
  }

  .switch-section {
    li {
      margin-right: 15px;
    }
  }

  .link-box {
    background-color: rgba($color-body, 0.9);
    @include position();
    padding: 15px;
    z-index: 1;
    width: 100%;
    max-width: 300px;

    .text-field {
      input {
        height: 30px;
        line-height: 30px;
        font-size: $fs-xs;
      }
    }
  }
}

.admin-content {
  .edit-doc {
    .edit-doc-content {
      width: calc(100% - 300px);
    }

    .doc-form-content {
      width: 300px;

      .checkbox {
        .checkbox-label {
          white-space: normal;
        }
      }
    }
  }
}

.doc-view-mode-header {
  h2 {
    max-width: calc(100% - 450px);
  }

  .card {
    li {
      max-width: 300px;
      display: flex;

      .label {
        white-space: nowrap;
      }

      .value {
        font-size: 14px;
      }

      a {
        @include text-overflow();
        max-width: 100%;
      }
    }
  }
}

.error {
  font-size: 14px;
  color: #e43535;
  text-align: center;
  font-weight: 500;
  margin-bottom: 0;
}

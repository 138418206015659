$icon-hyperlink: "\e91d";
$icon-link: "\e91d";
$icon-redo: "\e917";
$icon-undo: "\e918";
$icon-underline: "\e919";
$icon-strikethrough: "\e91a";
$icon-italic: "\e91b";
$icon-bold: "\e91c";
$icon-corner: "\e916";
$icon-add: "\e915";
$icon-print: "\e911";
$icon-download: "\e912";
$icon-note: "\e913";
$icon-edit: "\e914";
$icon-lock: "\e910";
$icon-arrow-up: "\e90e";
$icon-arrow-down: "\e90f";
$icon-camera: "\e90c";
$icon-avatar: "\e90d";
$icon-time: "\e90b";
$icon-delete: "\e909";
$icon-arrow-left: "\e90a";
$icon-file: "\e909";
$icon-link: "\e908";
$icon-close: "\e907";
$icon-upload: "\e904";
$icon-clock: "\e905";
$icon-check: "\e900";
$icon-doc: "\e901";
$icon-search: "\e902";
$icon-arrow-right: "\e903";
$icon-arrow-back: "\e906";
$icon-plus: "\e91e";
$icon-star: "\e91f";
$icon-eye: "\e920";
/* Pop up styles */

html[style*="calc"] {
  header {
    &.main {
      width: calc(100% - 17px);
    }
  }
}

.styles_modal__gNwvD {
  background: #ffffff;
  height: 100vh;
  width: 100vw;
  margin: -19px;
  max-width: 100vw;
  @media (min-width: 640px) {
    width: 100%;
    max-width: 570px;
    height: auto;
    margin: auto;
  }
  padding: 0;
}

// X icon

.styles_closeIcon__1QwbI {
  fill: $color-grey;
  transition: $trsn-dur;
  cursor: pointer;

  &:hover {
    opacity: 0.7;
  }
}

.popup-content {
  width: 100%;
  padding: 45px 40px;
  text-align: center;

  .popup-title {
    color: $color-primary;
    font-family: $font-secondary;
    font-weight: $fw-700;
    margin-bottom: 35px;
    text-transform: uppercase;
  }

  /* Popup custom styles */

  .popup-img {
    width: 100%;
    max-width: 136px;
    display: inline-block;
    text-align: center;
    padding-top: 17px;

    img {
      max-width: 100%;
      height: auto;
    }
  }

  .message {
    font-size: $fs-md;
    color: rgba($color-secondary, 0.6);
    padding-bottom: 25px;
    max-width: 400px;
    margin: 0 auto;
  }

  .popup-error-msg {
    color: $color-warning;
    margin-top: 33px;
    margin-bottom: 25px;
    text-align: center;
  }

  .options {
    border-bottom: 1px solid rgba(#000000, 0.2);
  }

  .ql-container,
  .ql-toolbar {
    &.ql-snow {
      border-color: rgba(#000000, 0.3);
    }
  }

  .ql-toolbar {
    &.ql-snow {
      border-top-left-radius: $br-lg;
      border-top-right-radius: $br-lg;
    }
  }

  .ql-container {
    &.ql-snow {
      border-bottom-left-radius: $br-lg;
      border-bottom-right-radius: 24px;
    }
  }

  .ql-editor {
    height: 230px;
  }

  .social-signup {
    li {
      max-width: 55px;
      margin-right: 5px;
    }
  }
}

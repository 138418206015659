/* Card styles */

.card {
  background-color: #ffffff;
  border: 1px solid rgba(#436baa, 0.2);
  box-shadow: 1px 1px 3px rgba(#4167a1, 0.2);

  &.br-round {
    border-radius: $br-lg;
  }

  &.br-corner {
    border-radius: $br-corner-xs;
  }

  &.highlight {
    box-shadow: 5px 5px 13px rgba($color-primary, 0.16);
  }

  .card-content {
    &.hide {
      display: none;
    }
  }

  &.w-md {
    width: 425px;
  }
}

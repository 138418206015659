/* admin import page */

.import {

	.file-import {
		position: relative;

		&.uploaded {
			display: flex;
			align-items: center;

			.file-upload {
				pointer-events: none;
			}

			.upload-section {
				width: 100%;
				max-width: 216px;
				opacity: 0.5;
			}

			.upload-process {
				display: flex;
			}
		}
		&.with-error {
			.upload-process {
				width: calc(100% - 347px);
			}
		}

		&.disabled {
			pointer-events: none;
			opacity: .5;
		}

		.upload-process {
			width: calc(100% - 216px);
			padding-left: 70px;
			display: none;

			.doc-image {
				max-width: 36px;
				margin-right: 25px;

				img {
					max-width: 100%;
					height: auto;
				}
			}

			.doc-info {
				width: calc(100% - 130px);
			}
		}

		.icon-file {
			font-size: 38px;
		}

		.error-msg {
			position: static;
			margin-top: 5px;
		}
	}

	.info-text {
		margin-top: 8px;
	}

	.import-loader {
		width: 100px;
		margin: 0 auto;
	}
}

/* Edit doc resp styles */


@media (max-width: 1360px) {

  .edit-doc {

    .edit-doc-items {
      flex-wrap: wrap;
    }

    .edit-doc-content, .doc-form-content {
      width: 100%;
    }

    .doc-form-content {
      padding-left: 0;
    }
  }

  .admin-content {

    .edit-doc {

      .edit-doc-content {
        width: 100%;
      }
      
      .doc-form-content {
        width: 100%;
        margin-top: 20px;
      }
    }
  }
}

/* Select-box styles */

.select-box {
	margin-bottom: 35px;
	position: relative;

	.checkbox {
		display: flex!important;
		align-items: center;
	}

	.react-select__control {
		min-height: 47px;
		font-size: $fs-md;
		font-family: $font-primary;
		color: $color-secondary;
		cursor: pointer;
	}

	.react-select-container {
		font-family: $font-primary;
		font-size: $fs-md;
		color: $color-secondary;
	}

	.react-select__control {
		box-shadow: none;
	}

	.react-select__value-container {
		padding: 0 26px;
	}

	.react-select__value-container, .react-select__single-value {
		@include text-overflow();
		max-width: 93%;
	}

	.react-select__indicator-separator {
		display: none;
	}

	.react-select__dropdown-indicator {
		padding-right: 30px;
	}

	.react-select__dropdown-indicator svg {
		fill: rgba(#000000, 0.5);
	}

	// Dropdown content

	.react-select__menu {
		margin-top: 0;
		border-radius: 0 0 6px 6px;
		box-shadow: 0 2px 4px rgba($color-secondary, 0.16);
		text-align: left;
		border: 2px solid $color-primary;
		font-size: $fs-sm;
		font-family: $font-primary;
	}

	.react-select__option--is-focused {
		background-color: rgba($color-secondary, 0.1);
		color: $color-primary;

		&:active {
			background-color: rgba($color-secondary, 0.2);
		}
	}

	.react-select__option--is-selected, .react-select__option {
		padding: 10px 27px;
		cursor: pointer;
	}

	.react-select__option--is-selected {
		background-color: rgba($color-primary, 0.15);
		color: $color-secondary;
	}

	// Placeholder

	.react-select__placeholder {
		color: rgba($color-secondary, 0.5);
	}

	&.br-corner {

		.react-select__control {
			border-width: 1px;
			border-style: solid;
			border-color:rgba($color-secondary, 0.3);
			border-radius: $br-corner-lg;
		}

		.react-select__control {
			border-radius: 24px;

			&.react-select__control--menu-is-open {

				&.react-select__control {
					border-radius: 24px 24px 0 0;
					border-width: 2px;
					border-color: $color-primary;
					border-bottom: none;
				}
			}
		}

		label {
			font-size: 13px;
			color: rgba(#000000, 0.5);
			display: inline-block;
			margin-bottom: 4px;
			margin-left: 28px;
		}
	}

	.value {
		position: absolute;
		z-index: 2;
		@include YAlign();
		margin-left: 28px;

		.circle {
			width: 5px;
			height: 5px;
			min-width: 5px;
			background: $color-secondary;
			border-radius: $br-circle;
			display: inline-block;
			margin: 0 10px 2px 10px;
		}

		&.hide {
			display: none;
		}
	}

	&.check-items {

		&.br-corner {

			label {
				margin-left: 0;
			}
		}
	}

	&.mb-0 {
		margin-bottom: 0;
	}

	&.invalid {

		&.br-corner {

			.react-select__control {
				border: 2px solid $color-warning;
			}
		}
	}

	&.disabled {
		pointer-events: none;
		opacity: 0.5;

		.react-select__control {
			background-color: rgba($color-blue-light, 0.4);
		}
	}
}


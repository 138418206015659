html {
  position: relative;
  min-height: 100%;
  box-sizing: border-box;
}

body {
  color: $color-text;
  font-size: $base-font-size;
  font-family: $font-primary;
  background-color: $color-body;
  outline: none;
  word-break: break-word;
  font-weight: 400;
}

*,
*:before,
*:after {
  outline: none;
  box-sizing: inherit;
}

.container {
  width: 100%;
  max-width: ($cont-width + $cont-padding);
  padding-left: ($cont-padding / 2);
  padding-right: ($cont-padding / 2);
  margin-left: auto;
  margin-right: auto;
}

.container-sm {
  width: 100%;
  max-width: ($cont-sm-width + $cont-padding);
  padding-left: ($cont-padding / 2);
  padding-right: ($cont-padding / 2);
  margin-left: auto;
  margin-right: auto;
}

a {
  transition: $trsn-dur;

  &:hover {
    opacity: 0.7;
  }
}

.main-content {
  padding: 62px 0;
  margin-top: 176px;
}

p,
a,
h1,
h2,
h3,
h4,
h5,
h6,
div,
span,
ul,
li,
header,
footer,
section,
main,
aside,
button,
input,
textarea,
label,
sub,
sup,
strong {
  font-family: $font-primary;
}

[contenteditable] {
  -webkit-user-select: text;
  user-select: text;
}

.material-icons.md-18 {
  font-size: 18px;
}
.material-icons.md-24 {
  font-size: 24px;
}
.material-icons.md-36 {
  font-size: 36px;
}
.material-icons.md-48 {
  font-size: 48px;
}
.material-icons.md-inherit {
  font-size: inherit;
}
.material-icons-outlined.md-18 {
  font-size: 18px;
}
.material-icons-outlined.md-24 {
  font-size: 24px;
}
.material-icons-outlined.md-36 {
  font-size: 36px;
}
.material-icons-outlined.md-48 {
  font-size: 48px;
}
.material-icons-outlined.md-inherit {
  font-size: inherit;
}
